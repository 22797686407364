<template>
  <div id="main">
    <el-main>
      <bread-crumb :type="3" :status="examInfo.exam_type==7?3:1"></bread-crumb>
      <div class="section">
        <img
          src="@/assets/fonts/SUCCESS.png"
          style="width:100px;height:100px;"
        />
        <div class="pay-status" v-if="examInfo.exam_type==7">
          报名成功
        </div>
        <div class="pay-status" v-else>
          支付成功
        </div>
        <div class="testBox">
          <div>
            {{ studentInfo.student_name }}同学，你已成功报名“{{
            examInfo.exam_name
            }}”，<span  v-if="examInfo.exam_type != 7">请于{{
            examInfo.download_zkzpdf_time
            }}下载准考证，</span>考试信息如下：
          </div>
          <div v-if="examInfo.exam_type == 7">预定考点省份：{{ examInfo.province }}</div>
          <div v-else>预定考点：{{ examInfo.city }}</div>
          <div>考试时间：{{ examInfo.execute_time }}</div>
          <div style="color:#999999;font-size:14px;margin-top:30px">
            报名成功详情可在“考试列表-查看报名信息”中查看，或关注“THUSSAT服务”服务号，接收考试提醒。
          </div>
        </div>
        <div class="btnBox">
          <el-button
            @click="toViewInfo()"
            style="background:#fff;color:#563279;border:1px solid #563279"
            >查看报名信息</el-button>
          <el-button
            @click="toExamList()"
            style="background:#563279;color:#fff;border:1px solid #563279"
            >返回考试列表</el-button>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import breadCrumb from "c/breadcrumb";
import { getOrderInfo } from "r/index/order.js";
export default {
  data() {
    return {
      examInfo: {},
      studentInfo: {},
    };
  },
  created() {
    if (
      !window.localStorage.getItem("exam_uuid") ||
      window.localStorage.getItem("exam_uuid") == "" ||
      window.localStorage.getItem("exam_uuid") == "null"
    ) {
      return this.$router.replace({ name: "examlist" });
    }
    this.getOrderInfo();
  },
  methods: {
    // 获取订单信息
    async getOrderInfo() {
      let { data: res } = await getOrderInfo({
        exam_uuid: window.localStorage.getItem("exam_uuid"),
        order_no: window.localStorage.getItem("order_no"),
      });
      console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.examInfo = res.info;
      this.studentInfo = res.student;
      console.log(this.examInfo.status);
      if (this.examInfo.status != 1) {
        return this.$router.replace({ name: "examlist" });
      }
    },
    // 查看报名信息
    toViewInfo() {
      window.localStorage.setItem("exam_uuid", this.examInfo.exam_uuid);
      window.localStorage.setItem("order_no", this.examInfo.order_no);
      this.$router.replace({ name: "ViewInfo" });
    },
    // 返回考试列表
    toExamList() {
      this.$router.replace({ name: "examlist" });
    },
  },
  components: {
    breadCrumb,
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  overflow: auto;
  min-height: 80%;
  .el-main {
    max-width: 1300px;
    margin: 6px auto;
    padding: 20px 50px;
  }
  .section {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 60px 0 80px;
    .pay-status {
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
      margin: 17px 0 30px;
    }
    .testBox {
      width: 620px;
      height: 108px;
      margin-bottom: 130px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      div:last-child {
        line-height: 20px;
      }
    }
    .btnBox {
      .el-button {
        width: 140px;
        margin-right: 40px;
        border: 1px solid #563279;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
